<template>
  <div id="container" class="map-wrapper">
    <div id="mapContainer"></div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import markerIcon from "./../assets/marker.svg";
import { getMellekhPoiTypes } from "./../helper/helper";
import localLevel from "./../assets/geojson/mellekh.json";

export default {
  name: "Map",
  props: {
    mapData: { type: Array, required: false }
  },
  data() {
    return {
      map: null, // leaflet map instance
      layers: [],
      layerGroup: null,
      polylineLayer: null,
      center: [29.2544717, 81.283471]
    };
  },
  methods: {
    /**
     * Setup the leaflet instance
     */
    setupLeafletMap: function () {
      let zoom = screen.width > 1920 ? 14 : 10;
      let accessToken =
        "uxM6uATt5sanMhGU7pS55XhLN7lTHL3UEzRSqBfYCH1cpMQssK1TAZejo0cwpDWG";

      //dark theme default tile layer
      var defaultDark = L.tileLayer(
        `https://{s}.tile.jawg.io/jawg-dark/{z}/{x}/{y}{r}.png?access-token=${accessToken}`,
        {
          // attribution: `<a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors`,
          accessToken: accessToken
        }
      );

      //satellite tilelayer
      var satellite = L.tileLayer(
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        // {
        //   attribution:
        //     "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
        // }
      );

      //add layer to the map
      this.map = L.map("mapContainer", {
        center: this.center,
        zoom: zoom,
        layers: [satellite, defaultDark]
      });

      //object for layer controls
      var baseMaps = {
        Satellite: satellite,
        Default: defaultDark
      };

      //add layer controls to map
      L.control.layers(baseMaps).addTo(this.map);

      //create geojson boundary layer
      let jsonLayer = L.geoJSON(localLevel, {
        style: function (feature) {
          return {
            color: "#AB8AF8", //for inside boundary
            fillOpacity: 0.01, //opcaity of filled color
            weight: 2 //boundary outline weight
          };
        }
      }).addTo(this.map);
      this.map.fitBounds(jsonLayer.getBounds());

      this.addMarker();
    },
    /**
     * Adds marker in maps
     */
    addMarker: function () {
      let iconSize = screen.width > 1920 ? 70 : 23;
      this.layerGroup = L.layerGroup().addTo(this.map);
      this.polylineLayer = L.layerGroup().addTo(this.map);

      this.mapData.map((item) => {
        //check if geolocation latitude and longitude values are null
        var isNull = item._geolocation.some((ele) => ele === null);

        if (item.surveyed_location_type === "road") {
          let lineCoordinate = [];
          let coordinates = item.road_line.split(";");

          for (var i in coordinates) {
            var pointJson = coordinates[i].split(" ").splice(0, 2);
            lineCoordinate.push([pointJson[0], pointJson[1]]);
          }

          L.polyline(lineCoordinate, {
            opacity: 0.7,
            dashArray: "5,5,1,5",
            lineJoin: "miter",
            lineCap: "square"
          })
            .bindPopup(this.getToolTipContent(item))
            .addTo(this.polylineLayer);
        } else {
          if (!isNull) {
            //adds marker in map
            L.marker(item._geolocation, {
              icon: L.icon({
                iconUrl: markerIcon,
                iconSize: iconSize,
                iconAnchor: [11, 41],
                popupAnchor: [0, -41]
              })
            })
              .bindPopup(this.getToolTipContent(item)) //bind tooltip to marker
              .addTo(this.layerGroup);
          }
        }
      });
    },
    /**
     * Display tooltip
     */
    getToolTipContent: function (data) {
      //get tooltip content
      let mappedValues = getMellekhPoiTypes(data);

      let popup = `<div class="rotate-img">
               <img class="img before-img" src="${mappedValues.photo_before}">
               <img class="img after-img" src="${mappedValues.photo_after}">
              </div><div class="main-info">
        <div class="poi-detail-title pd-bottom-28">
          <div class="heading">${mappedValues.full_name_location}</div>
          <div class="geo-location">
            N${mappedValues._geolocation.lat}" E${mappedValues._geolocation.long}"
          </div>
        </div><div class="other-info-wrapper"><div class="other-info">`;

      popup += `<dl><dt>वडा नं:</dt> <dd>${mappedValues.ward_no || ""}</dd></dl>
        <dl><dt>स्थानलाई हालकाे अवस्थामा ल्याउनका लागि अवलम्बन/लागू गरिएकाे याेजना एवं कार्यक्रमकाे नाम:</dt> <dd>${
          mappedValues.program_name || ""
        }</dd></dl>
        <dl><dt>गाउँ/टाेलकाे नाम:</dt> <dd>${
          mappedValues.village_tole_name || ""
        }</dd></dl>
        <dl><dt>स्थानलाई हालकाे अवस्थामा ल्याउनका लागि खर्च भएकाे लागत:</dt> <dd>${
          mappedValues.expenditure || ""
        }</dd></dl>
        <dl><dt>आयाेजना/कार्यक्रम सुरु भएकाे मिति:</dt> <dd>${
          mappedValues.program_start_date || ""
        }</dd></dl>
        <dl><dt>आयाेजना/कार्यक्रम अन्त्य भएकाे मिति:</dt> <dd>${
          mappedValues.program_end_date || ""
        }</dd></dl>`;

      for (const key in mappedValues.misc) {
        popup += `<dl><dt>${key}:</dt> <dd>${mappedValues.misc[key]}</dd></dl>`;
      }

      popup += `</div>
      <div class="contact">
        <div class="contact-person"><unicon name="user-square"></unicon> ${mappedValues.surveyor_name}</div>
      </div></div></div>`;

      return popup;
    },
    /**
     * Resets the markers
     */
    resetMarker: function () {
      this.layerGroup.clearLayers();
      this.polylineLayer.clearLayers();
      this.addMarker();
    }
  },
  mounted() {
    this.setupLeafletMap();

    window.onresize = () => {
      this.map.remove();
      this.setupLeafletMap();
    };
  },
  watch: {
    mapData: {
      handler: "resetMarker",
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
#mapContainer {
  /* border-left: 1px solid rgba(114, 115, 142, 0.2);
  height: 966px; */
  width: calc(100vw - 1400px);
  /* width: 2340px; */
  height: calc(100vh - 300px);
  z-index: 0;

  @media screen and (max-width: 3199px) {
    width: calc(100vw - 565px);
    height: calc(100vh - 108px);
  }

  @media screen and (max-width: 1800px) {
    width: calc(100vw - 499px);
  }
}

.map-wrapper {
  position: relative;

  &__search-input-wrapper {
    left: 70px;
    position: absolute;
    top: 10px;
    z-index: 1;
  }
}

/* .leaflet-marker-icon {
  @media screen and (max-width: 3199px) {
    width: 45px !important;
    height: 45px !important;
  }
} */
</style>
